import _ from "lodash";
import * as React from "react";
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Outlet, useNavigate, Link as RouterLink } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CardMedia from '@mui/material/CardMedia';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SellIcon from '@mui/icons-material/Sell';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { deepPurple } from '@mui/material/colors';

import Link from '../../components/Link';
import withRouter from '../../utils/withRouter';

import AuthButton from '../user/AuthButton';

import { Section, Title } from './Components';

function MarketingHome() {
  const navigate = useNavigate();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const reviews = [
    {
      avatar_url: "https://media-exp1.licdn.com/dms/image/C5603AQFE0zN2WZE1Dw/profile-displayphoto-shrink_800_800/0/1525902485712?e=1668643200&v=beta&t=pCTH7qSwMmMP7wJ0IfZRZ1BM3CL3p_sKHxkdhZ1EXp0",
      content: "I normally sell 1-2 annual memberships to my pickleball facility at this time of year. With TailRisk, I 10-xed that number.",
      name: "Anthony - Players Courtyard"
    },
    {
      avatar_url: "https://digitalnomads.world/wp-content/uploads/2021/04/traveling-yoga-teacher-digital-nomads.jpg",
      content: "We were giving away a free beer when the Phils hit a grand slam. Now we're giving away the entire cost of the check. Customers love it.",
      name: "Sam -- Yards Brewery."
    },
    {
      avatar_url: "https://assets.website-files.com/61638e504dc9728345e07015/6164e654c1b9a04ad351f104_avatar-04.jpg",
      content: "My local medical student customers are cost conscience. When I offered a full refund on my gift cards tied to sports, they bought it up.",
      name: "Aaron -- Habitat Coffee"
    },
    ]

    return (
      <Box pt={8}>
        <Container maxWidth="lg" sx={{background: "whitesmoke", borderRadius: 10}}>
          <Container maxWidth="md">
            <Grid container spacing={8} alignItems="center">
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant="h1" textAlign="center" style={{
                  fontSize: mobile ? 30 : 50,
                  lineHeight: "1",
                  fontWeight: "500",
                  letterSpacing: "-0.03em"
                }}>
                  Gamified promotions <span style={{
                    backgroundImage: "url('https://assets.website-files.com/613d5c4…/61493dd…_scribble-3_1.svg')",
                    backgroundPosition: "50% 100%",
                    backgroundSize: "auto",
                    backgroundRepeat: "no-repeat"
                  }}>for fans</span>
                </Typography>

                <Typography mt={1} variant={mobile ? "h5" : "h4"} fontWeight={400} textAlign="center">
                  Shoppers save on everyday shopping when cool things happen in sports and culture.
                </Typography>

                <Grid mt={2} container spacing={2} direction="row">
                  <Grid item xs={12}>
                    <AuthButton screen={"signup"} onAuthentication={() => {
                      navigate("/auth/merchants")
                    }}>
                      <Button fullWidth variant="contained" size="large">
                        Get Started
                      </Button>
                    </AuthButton>

                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={2}>
                    <Link textAlign="center" variant="body2" color="secondary" underline="hover" fontWeight={600} component={RouterLink} to="/shoppers">
                      Already  received a promotion as a shopper?
                    </Link>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={12} alignItems="center">
                <Grid item style={{
                  background: "url(https://p49.tr4.n0.cdn.zight.com/items/E0uJw8P2/0906c8de-72f3-4f8e-9534-9b9a58bf98bb.jpeg?source=viewer&v=a5d6b547c682c2014cc633a4e88b1c14)",
                  backgroundSize: "cover",
                  height: "70vh",
                }}>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Container>

        <Box mt={12}>
          <Container maxWidth="lg">
            <Box>
              <Typography color="secondary" variant="h5" fontWeight={600}>
                Meet TailRisk
              </Typography>

              <Title>
                Maximum ROI, minimum effort
              </Title>
            </Box>

            <Box pt={6} pb={12}>
              <Container maxWidth="md">
                <Grid container spacing={{
                  xs: 2,
                  md: 6
                }} alignItems="center">
                  <Grid item xs={12} sm={5} order={{ xs: 2, md: 1 }}>
                      <img
                        style={{
                          height: 300,
                          borderRadius: "28px"
                        }}
                        src="https://p49.tr4.n0.cdn.zight.com/items/7Kun7kBL/cebb906d-6610-4d9f-8e88-0b2744833dec.png?source=viewer&v=0206540c48d22c57620b6d4cbcc046a9"/>


                  </Grid>

                  <Grid item xs={12} sm={6} order={{ xs: 1, md: 2 }}>
                    <Typography variant="h5">
                      Shoppers love winning big financially
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <Link color="secondary" target="_blank" href="https://www.furnituretoday.com/retailers/mattress-macks-astros-promotion-is-gallerys-biggest-as-houston-makes-fall-classic/">
                        Ask Mattress Mack in Houston
                      </Link>. He has famously given away $70M in promotional rebates in a hyper successful campaign tied to the Astros winning the world series.
                      He realized that shoppers greatly prefer a 3% chance at a huge refund
                      versus an equivalent 3% discount.
                    </Typography>
                  </Grid>
                </Grid>

                <Box mt={12}>
                  <Grid container spacing={{
                    xs: 2,
                    md: 6
                  }} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h5">
                        Shoppers love sports
                      </Typography>
                      <Typography variant="body2">
                        Jordan's furniture company has also profited in an outsized way
                        from this insight with their <Link color="secondary" target="_blank" href="https://www.furnituretoday.com/retailers/mattress-macks-astros-promotion-is-gallerys-biggest-as-houston-makes-fall-classic/">
                          Cycle Red Sox campaign
                        </Link>. They ran this $35M volume promotion successfully for 15 years, capturing massive regional interest.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <img height={300} src="https://p49.tr4.n0.cdn.zight.com/items/2Nu9g7KW/a279735c-cc70-4d0e-9fec-307ff152dfbd.png?source=viewer&v=4725ec726c65b2a0cc7b220fbe907dfe"/>

                    </Grid>
                  </Grid>
                </Box>

                <Box mt={12}>
                  <Grid container spacing={{
                    xs: 2,
                    md: 6
                  }} alignItems="center">
                    <Grid item xs={12} sm={5} order={{ xs: 2, md: 1 }}>
                      <img
                        style={{
                          width: "100%",
                          borderRadius: "28px"
                        }}
                        src="https://p49.tr4.n0.cdn.zight.com/items/L1uyLXom/93ff7755-0db7-4b4a-989f-6821e6916dce.png?source=viewer&v=191db4e445c9efff71e7e69315d1b0fa" />
                    </Grid>
                    <Grid item xs={12} sm={6} order={{ xs: 1, md: 2 }}>
                      <Typography variant="h5">
                        Marketing teams love ease
                      </Typography>
                      <Typography variant="body2">
                        Historically, running a probabilistic sports promotion like <Link
                         href="https://www.tacobell.com/promotions/steal-a-taco" target="_blank" color="secondary">
                         Taco Bell's Steal Home Base
                        </Link> would have been too complex for the average marketing team due to balance sheet risk, reporting, ops, etc.
                        We remove all those hurdles to make it super simple.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Container>

            </Box>
          </Container>
        </Box>


        <Box sx={{
          background: "#141c3f",
          paddingTop: 20,
          paddingBottom: 20
        }}>
          <Container maxWidth="lg">
            <Box mb={6}>
              <Typography variant="h2" color="#fff" style={{
                fontSize: "3em",
                letterSpacing: "-0.03em"
              }}>
                Diving in to the stats
              </Typography>
              <Typography variant="body1" color="#fff">
                Why do shoppers prefer gamified, high upside sports promotions to standard discounts?
              </Typography>
            </Box>

            <Grid container spacing={4} alignItems="center" justifyContent="space-between">
              <Grid item xs={6} md={6} lg={3}>
                <Box>
                  <Typography variant="h3" color="#fff" style={{
                    fontSize: "2em",
                    lineHeight: "1",
                    fontWeight: "500",
                    letterSpacing: "-0.03em"
                  }}>
                    3B+
                  </Typography>
                  <Typography variant="body1" color="#177bc7" fontWeight={500}>
                    Global sports fans
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={6} lg={3}>
                <Box>
                  <Typography variant="h3" color="#fff" style={{
                    fontSize: "2em",
                    lineHeight: "1",
                    fontWeight: "500",
                    letterSpacing: "-0.03em"
                  }}>
                    2 in 3
                  </Typography>
                  <Typography variant="body1" color="#177bc7" fontWeight={500}>
                    Americans report as fans
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={6} lg={3}>
                <Box>
                  <Typography variant="h3" color="#fff" style={{
                    fontSize: "2em",
                    lineHeight: "1",
                    fontWeight: "500",
                    letterSpacing: "-0.03em"
                  }}>
                    $120B
                  </Typography>
                  <Typography variant="body1" color="#177bc7" fontWeight={500}>
                    Sports wagers in 2023
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={6} lg={3}>
                <Box>
                  <Typography variant="h3" color="#fff" style={{
                    fontSize: "2em",
                    lineHeight: "1",
                    fontWeight: "500",
                    letterSpacing: "-0.03em"
                  }}>
                    68M
                  </Typography>
                  <Typography variant="body1" color="#177bc7" fontWeight={500}>
                    Americans bet on the SB
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box pt={10} pb={10} sx={{background: "whitesmoke"}}>
          <Container maxWidth="lg">
            <Typography color="secondary" variant="h5" fontWeight={600} textAlign="center">
              So how does it work?
            </Typography>

            <Typography variant="h3" textAlign="center" style={{
              fontSize: mobile ? 22 : 30,
              lineHeight: 1.4
            }}>
              We make running hyper compelling sports promotions simple
            </Typography>

            <Box mt={4}>
              <Grid container spacing={4} alignItems="center" justifyContent="space-between">
                <Grid item xs={12} md={6} lg={4}>
                  <Paper variant="outlined" sx={{
                    height: 300,
                    p: 3
                  }}>
                    <Avatar sx={{
                      height: 40,
                      width: 40,
                      bgcolor: deepPurple[500],
                      fontWeight: 600
                    }}>
                      <AccountBalanceIcon />
                    </Avatar>

                    <Box mt={2}>
                      <Typography variant="h5">
                        Risk Transfer
                      </Typography>
                     <Typography color="text.secondary" variant="body2">
                        The average marketer cannot approach their CFO with a budget
                        ranging anywhere from $50k to $50M for a promotion whose cost depends on a probabilitic outcome.
                        We transfer that risk off your balance sheet via reinsurance so that you can precisely control your costs.
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Paper variant="outlined" sx={{
                    height: 300,
                    p: 3
                  }}>
                    <Avatar sx={{
                      height: 40,
                      width: 40,
                      bgcolor: deepPurple[500],
                      fontWeight: 600
                    }}>
                      <SellIcon />
                    </Avatar>

                    <Box mt={2}>
                      <Typography variant="h5">
                        AI Content Generation
                      </Typography>
                      <Typography color="text.secondary" variant="body2">
                        Should you give away thousands of dollars of merchandise if Bryce Harper hits 3 homers in a game?
                        How about a grandslam? With our AI underwriting engine, we'll identify in-game or season long sporting outcomes that align with your marketing goals.
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Paper variant="outlined" sx={{
                    height: 300,
                    p: 3
                  }}>
                    <Avatar sx={{
                      height: 40,
                      width: 40,
                      bgcolor: deepPurple[500],
                      fontWeight: 600
                    }}>
                      <QrCodeIcon />
                    </Avatar>

                    <Box mt={2}>
                      <Typography variant="h5">
                        Product and Ops
                      </Typography>
                     <Typography color="text.secondary" variant="body2">
                        As a marketer, you have limited engineering or product
                        resources to implement things like user notifications,
                        in-app collateral, payment refunds, etc. We handle
                        all of this for you with our tech platform so you
                        can trivially author and launch hyper compelling campaigns.
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Paper variant="outlined" sx={{
                    height: 300,
                    p: 3
                  }}>
                    <Avatar sx={{
                      height: 40,
                      width: 40,
                      bgcolor: deepPurple[500],
                      fontWeight: 600
                    }}>
                      <QueryStatsIcon />
                    </Avatar>

                    <Box mt={2}>
                      <Typography variant="h5">
                        Reporting and Analytics
                      </Typography>
                     <Typography color="text.secondary" variant="body2">
                        Ultimately, these probabilitic promotions are intended to create
                        arbitrage for marketers compared to standard discounts. Our analytics stack helps marketers
                        measure incrementality.
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Paper variant="outlined" sx={{
                    height: 300,
                    p: 3
                  }}>
                    <Avatar sx={{
                      height: 40,
                      width: 40,
                      bgcolor: deepPurple[500],
                      fontWeight: 600
                    }}>
                      <LocalPoliceIcon />
                    </Avatar>

                    <Box mt={2}>
                      <Typography variant="h5">
                        Regulatory
                      </Typography>
                     <Typography color="text.secondary" variant="body2">
                        We work with a licensed reinsurer with billions of dollars in policies to
                        insure that we are fully compliant with local regulations as well as eliminate
                        counterparty risk.
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Paper variant="outlined" sx={{
                    height: 300,
                    p: 3
                  }}>
                    <Avatar sx={{
                      height: 40,
                      width: 40,
                      bgcolor: deepPurple[500],
                      fontWeight: 600
                    }}>
                      <PeopleAltIcon />
                    </Avatar>

                    <Box mt={2}>
                      <Typography variant="h5">
                        Distribution
                      </Typography>
                     <Typography color="text.secondary" variant="body2">
                        We help marketing teams distribute these engaging campaigns not only within their apps
                        and social media but also through our own network of end users.
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>

              </Grid>
            </Box>
          </Container>
        </Box>

        <Box sx={{
          pt: 14,
          pb: 14,
          height: "80vh",
          width: "100%",
          backgroundSize: "cover",
          backgroundImage: "url('https://assets.website-files.com/613d5c4dc4abb8c7f1d44b8f/615ba839331cb89fb056c6e3_sharon-mccutcheon--8a5eJ1-mmQ-unsplash%20(1).jpg')"
        }}>
          <Container maxWidth="md">
            <Title color="#fff" mb={4}>
              Championship level promotions for shoppers.
            </Title>

            <AuthButton screen={"signup"} onAuthentication={() => {
              navigate("/auth/merchants")
            }}>
              <Button variant="contained" size="large" color="secondary">
                Get Started
              </Button>
            </AuthButton>
          </Container>
        </Box>
      </Box>
    );

}

const select = $$state => _.pick($$state, ["user"]);
export default connect(select, {})(withRouter(MarketingHome));
