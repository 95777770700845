import _ from "lodash";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

import Logo from '../../components/Logo';
import Link from '../../components/Link';

function MarketingFooter(props) {
  return (
   <AppBar
      position="static"
      elevation={0}
      sx={{
        p: 4,
        marginTop: 0,
        bgcolor: "#111029",
        color: "#fff",
      }}>

      <Grid container spacing={{
        sm: 0,
        xs: 4
      }}>
        <Grid item xs={12} sm={6}>
          <Box>
            <Link color="#fff" variant="body2" fontWeight={600} component={RouterLink} to="/">
              TailRisk
            </Link>
            <Typography variant="body3">
              Sports promotions for shoppers
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack direction={"row"} spacing={{
            md: 8,
            xs: 6
          }}>

            <Box>
              <Box mt={1}>
                <Stack spacing={1}>
                  <Link color="#fff" variant="body2" href={"/about"}>
                    About
                  </Link>

                  <Link color="white" variant="body2" href={"/careers"}>
                    Careers
                  </Link>
                </Stack>
              </Box>
            </Box>

            <Box>
              <Box mt={1}>
                <Stack spacing={1}>
                  <Link color="white" variant="body2" href={"/pricing"}>
                    Pricing
                  </Link>

                  <Link color="white" variant="body2" href={"mailto:help@tailrisk.ai"}>
                    Contact
                  </Link>
                </Stack>
              </Box>
            </Box>

            <Box>
              <Box mt={1}>
                <Stack spacing={1}>
                  <Link color="white" variant="body2" href={"/privacy"}>
                    Privacy
                  </Link>

                  <Link color="white" variant="body2" href={"/terms"}>
                    Terms
                  </Link>
                </Stack>
              </Box>
            </Box>
          </Stack>

          {
            false &&
              <Box mt={3}>
                <Stack direction="row" spacing={2}>
                  <LinkedInIcon sx={{fontSize: 36}} />
                  <TwitterIcon sx={{fontSize: 36}} />
                  <img
                    style={{height: 30}}
                    src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6cc3c481a15a141738_icon_clyde_white_RGB.png" />
                </Stack>
              </Box>
          }
        </Grid>
      </Grid>
    </AppBar>
  );
}

export default MarketingFooter;
